<template>
  <div>
    <CSBreadcrumb/>
    <div class="result-panel">
      <template v-if="type === OPERATIONAL_ITEM_TYPE.ACTIVE_DATA">
        <CSTable>
          <template v-slot:header>
            <div class="table-header-panel">
              <span>活动发布数:{{ activityDetailData.releaseCount || 0}}</span>
              <span style="margin-left:29px">访问数:{{ activityDetailData.visitCount }}</span>
              <template v-if="isEnroll">
                <span style="margin-left:28px">报名人数:{{ activityDetailData.enrollCount }}</span>
                <span style="margin-left:28px">签到人数:{{ activityDetailData.signCount }}</span>
              </template>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>发布时间</th>
              <th>活动名称</th>
              <th>活动内容</th>
              <th>访问人数</th>
              <template v-if="isEnroll">
                <th>报名人数</th>
                <th>签到人数</th>
              </template>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="item in activityDetailData" :key="item.id">
              <td>{{ item.createTime }}</td>
              <td>{{ item.title }}</td>
              <td><a href="javascript:void(0);" @click="activitiContentData=item;activityPreviewVisible=true">查看</a>
              </td>
              <td>{{ item.uvCount }}</td>
              <template v-if="isEnroll">
                <td>{{ item.applyCount }}</td>
                <td>{{ item.registerCount }}</td>
              </template>
            </tr>
          </template>
        </CSTable>
      </template>

      <template v-if="type === OPERATIONAL_ITEM_TYPE.COMPLAINT_DATA">
        <CSTable>
          <template v-slot:header>
            <div class="table-header-panel">
              <span>投诉数:{{ complaintDetailData.reportNum }}</span>
              <span style="margin-left:26px">已处理数:{{ complaintDetailData.handleNum }}</span>
              <span style="margin-left:27px">评论数:{{ complaintDetailData.evaluationNum }}</span>
              <span style="margin-left:26px">平均星级数:{{ complaintDetailData.avgScore }}</span>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>投诉时间</th>
              <th>姓名</th>
              <th>手机号</th>
              <th>内容</th>
              <th>图片</th>
              <th>是否处理</th>
              <th>是否评价</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="item in complaintDetailData" :key="item.id">
              <td>{{ item.createTime }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.phone }}</td>
              <td><a class="allow-click" href="javascript:void(0);" id="complaintContent"
                     @click="alter('投诉内容',item.content)">查看</a></td>
              <td>
                <span @click="lookImg(item.photos)" class="allow-click" v-if="item.photos">查看</span>
                <span v-else>-</span>
              </td>
              <td>{{ item.isHandle == true ? "已处理" : "未处理" }}</td>
              <td><a href="javascript:void(0);" @click="item.score!=null?complaintEvaluationAlter(item):''"
                     :class="{aExistStyle:item.score!=null,aNotStyle:item.score==null}">{{
                  item.score != null ? item.score + "星" : "-"
                }}</a>
              </td>
            </tr>
          </template>
        </CSTable>
      </template>

      <template v-if="type === OPERATIONAL_ITEM_TYPE.QUESTIONNAIRE_DATA">
        <CSTable>
          <template v-slot:header>
            <div class="table-header-panel">
              <span>问卷发放数:{{ surveyDetailData.sendCount }}</span>
              <span style="margin-left:76px">提交数:{{ surveyDetailData.submitCount }}</span>
            </div>
          </template>
          <template v-slot:thead>
            <tr>
              <th>问卷名称</th>
              <th>问卷内容</th>
              <th>发放数</th>
              <th>提交数</th>
              <th>数据统计</th>
            </tr>
          </template>
          <template v-slot:tbody>
            <tr v-for="item in surveyDetailData" :key="item.id" style="height:50px;">
              <td>{{ item.name }}</td>
              <td><a href="javascript:void(0);" @click="getSurveyContentData(item.id)">查看</a></td>
              <td>{{ item.sendCount }}</td>
              <td>{{ item.submitCount }}</td>
              <td><a href="javascript:void(0);" @click="lookStatistics(item.id)">查看</a></td>
            </tr>
          </template>
        </CSTable>
      </template>
    </div>

    <!--  活动查看  -->
    <ActivityPreview
        :visible="activityPreviewVisible"
        @closeModal="activityPreviewVisible=false"
        :addActivityList="activitiContentData"

    >
      <template v-slot:btnGroup>
        <div class="padding-top: 10px;">
          <button
              style="width: 80px; height: 40px;border-radius: 6px;line-height: 25px;margin-left:50%; transform:translateX(-50%);font-size: 24px;"
              class="btn btn-primary"
              @click="activityPreviewVisible=false"
          >
            关闭
          </button>
        </div>
      </template>
    </ActivityPreview>

    <!-- 问卷内容 -->
    <div class="preview-layer" v-show="surveyContentEnable">
      <QuestionnairePreview :data="previewData"
                            style="position:absolute;top:50px;left:50%;transform: translateX(-50%);z-index:2002;">
        <template v-slot:footer>
          <div class="step-footer" style="padding:0;margin-top:-20px;border-top:0;">
            <button class="btn btn-primary" @click="surveyContentEnable=false"
                    style="line-height:25px;width: 80px; height: 40px;border-radius: 6px;">关闭
            </button>
          </div>
        </template>
      </QuestionnairePreview>
    </div>
    <ViewImageModal/>
  </div>
</template>

<script>
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
import QuestionnairePreview from "@/components/phonePreview/QuestionnairePreview";
import {OPERATIONAL_ITEM_TYPE} from "@/views/Statistics/operationalItemType";
import ViewImageModal from "@/components/ViewImageModal";

import {
  adminQueryUrl,
  complaintDataUrl,
  getadminQueryUrl,
  querySingleSurveyUrl,
  queryTicketPropertyDataUrl
} from "@/requestUrl";
import ActivityPreview from "@/components/phonePreview/ActivityPreview";
import {STORAGE_KEY} from "@/constant";
import CSTable from "@/components/common/CSTable";

export default {
  name: "OperationalItemDetail",
  isLoading: true,
  props: {
    type: Number,
    params: Object,
    itemData: Object,
    startDate: String,
    endDate: String,
    regionCode: String,
    containDelete: {
      type: Number,
      default: 0
    },
    startTime: String,
    endTime: String,
    isEnroll: Number,
  },
  components: {
    CSTable,
    CSBreadcrumb,
    ActivityPreview,
    QuestionnairePreview,
    ViewImageModal,
  },
  created() {
    this.pageData = JSON.parse(sessionStorage.getItem(STORAGE_KEY.OPERATIONAL_NEXT_PAGE_DATA));
    const initDataHandler = {
      [OPERATIONAL_ITEM_TYPE.QUESTIONNAIRE_DATA]: this.querySurveyDetailData,
      [OPERATIONAL_ITEM_TYPE.COMPLAINT_DATA]: this.queryComplaintDetailData,
      [OPERATIONAL_ITEM_TYPE.ACTIVE_DATA]: this.getActivityDetailData,
    }
    initDataHandler[this.type]();
  },
  data() {
    return {
      OPERATIONAL_ITEM_TYPE,
      activityDetailData: {}, // 活动数据
      activityPreviewVisible: false,
      activitiContentData: {},
      previewData: {},
      complaintDetailData: {},
      surveyDetailData: {},
      pageData: {},
      surveyContentEnable: false,
    }
  },
  methods: {
    lookImg(photos) {
      this.$vc.emit(this.$route.path, "viewImageModal", "view", JSON.parse(photos));
    },
    complaintEvaluationAlter(item) {
      this.$CSDialog.alert({
        center: true,
        title: "查看评价",
        messageHtml: `
                    <table CellPadding="15px" style="text-align:left">
                        <tr>
                            <td>评价时间</td><td>${item.evaluateTime}</td>
                        </tr>
                        <tr>
                            <td>评价星级</td><td>${item.score}</td>
                        </tr>
                        <tr>
                            <td>评价内容</td><td>${item.evaluateContent}</td>
                        </tr>
                    </table>
                `
      })
    },
    //获取问卷内容
    getSurveyContentData(id) {
      this.$fly.post(querySingleSurveyUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        id
      })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.updateLoading(false);
            res.data.questions = res.data.questions.map(question => {
              if (question.type !== 3) {
                question.options = JSON.parse(question.options);
              }
              return question;
            })
            this.previewData = res.data;
            this.surveyContentEnable = true;
          });
    },
    // 查看数据统计
    lookStatistics(id) {
      const {timeSelect, startDate, endDate} = this;
      sessionStorage.setItem(STORAGE_KEY.OPERATIONAL_QUERY_PARAMS, JSON.stringify({
        timeSelect,
        startDate,
        endDate,
      }))
      this.$router.push({name: "questionnaireLog", query: {id}});
    },
    alter(title, content) {
      this.$CSDialog.alert({
        title: title,
        center: true,
        messageHtml: `
                    <span style='font-size:20px'>${content}</span>
                `
      })
    },
    //查询投诉详情数据
    queryComplaintDetailData() {
      const startTime = this.startTime + ':01';
      const endTime = this.endTime + ':59';
      this.$fly.post(complaintDataUrl, {
        regionId: this.$vc.getCurrentRegion().code,
        startTime,
        endTime
      })
          .then((res) => {
            if (res.code != 0) {
              return;
            }

            if (res.data == null) {
              this.updateLoading(false);
            }
            this.complaintDetailData = res.data;
            for (let key in this.pageData) {
              this.complaintDetailData[key] = this.pageData[key];
            }
            this.complaintEnable = true;
          });
    },
    //查询问卷详情数据
    querySurveyDetailData() {
      const startDate = this.startDate;
      const endDate = this.endDate;
      this.$fly.post(adminQueryUrl, {
        regionCode: this.$vc.getCurrentRegion().code,
        startDate,
        endDate,
        containDelete: 0
      })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            if (res.data == null) {
              this.updateLoading(false);
            }
            this.surveyDetailData = res.data;
            for (let key in this.pageData) {
              this.surveyDetailData[key] = this.pageData[key];
            }
          })
    },
    // 获取活动数据
    getActivityDetailData() {
      const {
        regionCode,
        startDate,
        endDate,
        //为1查询需报名活动 为0查无需报名活动
        isEnroll
      } = this;
      this.$fly.post(getadminQueryUrl, {
        regionCode,
        startDate,
        endDate,
        //为1查询需报名活动 为0查无需报名活动
        isEnroll,
        containDelete: 2
      }).then((res) => {
        if (res.code != 0) {
          return;
        }
        if (res.data.datas == null) {
          this.updateLoading(false);
        }
        this.activityDetailData = res.data.datas;
        for (let key in this.pageData) {
          this.activityDetailData[key] = this.pageData[key];
        }
      });
    }
  }
}
</script>

<style scoped>

</style>
